import React from 'react'
import Newsletter from "../Components/Newsletter"

const donation = () => {
  return (
    <>
    <div className="page-title">
        <h2 className="font-bold text-center pt-32 lg:pt-60 pb-20 text-white text-5xl">Donate</h2>
    </div>
    <section className='py-24'>
      <div className='container mx-auto px-24'>
        <h3 className='text-4xl md:text-5xl my-4 text-sky-600 font-bold'>Every penny you donate goes a long way in making a difference</h3>

      </div>
    </section>
    <Newsletter />
    </>
  )
}

export default donation