import React from 'react'
import { Link } from 'react-router-dom'
import CharityDonation from '../images/charityDonation.jpg'
import TerraYsa from "../images/terra-ysa.jpg"
import treePlanting1 from '../images/treePlanting1.jpg'
import StreetCampaign from "../images/streetCampaign.jpg"
import terrafacts from  "../images/terrafacts.jpg"
import PodcastImage from "../images/podcast.PNG"


const projects = () => {
  return (
    <>
    <div className="page-title">
      <h2 className="font-bold text-center pt-32 lg:pt-60 pb-20 text-white text-5xl">Projects</h2>
    </div>
    <section className='py-24'>
      <div className='project-big-card grid sm:grid-cols-2 lg:grid lg:grid-cols-3 gap-2 mb-4'>
        <Link to="/projects/TerraPlus-Ghana-partners-with-YSA" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
          <small className="text-stone-500">15th August, 2023</small>
          <p className='text-md my-4 text-stone-800'></p>
          <img 
            src={TerraYsa}
            alt="project logo"
          />
          <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana partners with YourSchoolAid (YSA)</h2>
          <p className='text-md my-4 text-stone-800'>TerraPlus Ghana has joined hands with YourSchoolAid, a school management system which aims to connect parents, teachers and students for academic success.</p>
          <Link to="/projects/TerraPlus-Ghana-partners-with-YSA">
            <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
          </Link>
        </Link>

        <Link to="/projects/TerraPlus-Ghana-embarks-on-beat-plastic-pollution-campaign" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
          <small className="text-stone-500">4th July, 2023</small>
          <img 
            src={StreetCampaign}
            alt="project logo"
          />
          <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana embarks on a "beat plastic pollution" street campaign </h2>
          <p className='text-md my-4 text-stone-800'>An extraordinary event unfolded on the 4th of June at 15:00 GMT, as TerraPlus Ghana took a bold step forward in their mission to combat plastic pollution.</p>
          <Link to={"/projects/TerraPlus-Ghana-embarks-on-beat-plastic-pollution-campaign"}>
            <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
          </Link>
        </Link>

        <Link to="/projects/TerraPlus-Ghana-podcast" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
          <small className="text-stone-500">11th May, 2023</small>
          <img 
            src={PodcastImage}
            alt="project logo"
          />
          <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana Podcast </h2>
          <p className='text-md my-4 text-stone-800'> The podcast initiative by terraplus utilises audio-visual approach to educate our targeted audience on environmental issues</p>
          <Link to={"/projects/TerraPlus-Ghana-podcast"}>
            <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
          </Link>
        </Link>

        <Link to='/projects/TerraPlus-Ghana-terrafacts' className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
          <small className="text-stone-500">20th February, 2023</small>
          <img 
            src={terrafacts}
            alt="project logo"
          />
          <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">Terrafacts </h2>
          <p className='text-md my-4 text-stone-800'>Terrafacts is an initiative by Terraplus Ghana to educate the public on sentimental facts about the need in the attempt to conscientize our audience into joining the campaign for a sustainable environment</p>
          <Link to={'/projects/TerraPlus-Ghana-terrafacts'}>
            <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
          </Link>
        </Link>
        <Link to="/projects/Terraplus-Ghana-tree-planting-exercise-2022/" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
          <small className="text-stone-500">5th June, 2022</small>
          <img 
            src={treePlanting1}
            alt="project logo"
          />
          <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana Embarks on Tree Planting Exercise</h2>
          <p className='text-md my-4 text-stone-800'>Terra plus Ghana in collaboration with other environmental bodies undertook a tree planting exercise in and around the environs of KWAME NKRUMAH UNIVERSITY OF SCIENCE AND TECHNOLOGY.</p>
          <Link to={"/projects/Terraplus-Ghana-tree-planting-exercise-2022/"}>
            <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
          </Link>
        </Link>

        {/* project 2 */}
        <Link to="/projects/TerraPlus-Ghana-donates-to-King-Jesus-Charity-Home" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
          <small className="text-stone-500">13th August, 2022</small>
          <img 
            src={CharityDonation}
            alt="project logo"
          />
          <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana Donates to King Jesus Charity Home</h2>
          <p className='text-md my-4 text-stone-800'>TerraPlus Ghana LBG, a newly formed NGO whose mission and vision is to create a sustainable and comfortable environmental conditions...</p>
          <Link to={"/projects/TerraPlus-Ghana-donates-to-King-Jesus-Charity-Home"}>
            <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
          </Link>
        </Link>
        
      </div>
    </section>
    </>
  )
}

export default projects