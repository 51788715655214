import React from 'react'
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { AiOutlinePhone } from "react-icons/ai";
import { CiLocationOn } from "react-icons/ci";
import Newsletter from '../Components/Newsletter';



const contact = () => {
  return (
    <>
        <div className="page-title">
            <h2 className="font-bold text-center pt-32 lg:pt-60 pb-20 text-white text-5xl">Contact Us</h2>
        </div>
        <section className='contact py-24'>
            <div className="container mx-auto px-4">
                <div className="grid sm:grid-cols-2 gap-8">
                    <form className='border-2 p-8 rounded-lg shadow-lg' action="https://formsubmit.co/membership.terraplus@gmail.com" method="POST">
                        <input type="hidden" name="Contactform" />
                        <input type="hidden" name="_next" value="https://terraplusghana.org/thanks"></input>
                        <h3 className='text-3xl md:text-4xl text-sky-500 font-bold py-4'>Send us a message via the contact form below</h3>
                        <div className='form-group m-2'>
                            <input className="border p-4 w-full" name='name' placeholder='Enter Name' type="text"/>
                        </div>
                        <div className='form-group flex gap-x-2 m-2'>
                            <input className="border p-4 w-full" name='email' placeholder='Enter Email' type="email"/>
                            <input className="border p-4 w-full" name='phone' placeholder='Enter Phone' type="text"/>
                        </div>
                        <div className='form-group m-2'>
                            <textarea name='ContactMesage' className="border p-4 w-full"></textarea>
                        </div>
                        <div className='form-group m-2'>
                            <input className="border p-4 w-full button-primary text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:ring-sky-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-sky-700 focus:outline-none dark:focus:ring-sky-800" placeholder='Enter Phone' value="Send Message" type="submit"/>
                        </div>
                    </form>

                    <div className='contact-details'>
                        <div className='contact-social grid grid-cols-2 md:grid-cols-3'>
                            <ul className='text-xl text-stone-800'>
                                <li className='flex py-4'><AiOutlinePhone className='text-lg'/>+233 545211903</li>
                                <li className='flex py-4'><AiOutlinePhone className='text-lg'/>+233 257331993</li>
                                <li className='flex py-4'><CiLocationOn className='text-lg'/> 184 Montreal Street Ablekuma, Ga Central, Greater Accra, Ghana</li>
                            </ul>
                            <a href="https://www.https://web.facebook.com/Terraplus-Ghana-107221035291174/" className="pointer bg-sky-500 m-3 h-fit p-10"><BsFacebook className="text-white text-center mx-auto text-7xl"/></a>
                            <a href="https://www.twitter.com/terraplus_ghana" className="pointer bg-sky-500 m-3 h-fit p-10"><BsTwitter className="text-white text-center mx-auto text-7xl"/></a>
                            <a href="https://www.instagram.com/terraplus_ghana/" className="pointer bg-sky-500 m-3 h-fit p-10"><BsInstagram className="text-white text-center mx-auto text-7xl"/></a>
                            <a href="https://gh.linkedin.com/company/terraplus-ghana" className="pointer bg-sky-500 m-3 h-fit p-10"><BsLinkedin className="text-white text-center mx-auto text-7xl"/></a>
                            <a href="https://api.whatsapp.com/send/?phone=0545211903" className="pointer bg-sky-500 m-3 h-fit p-10"><AiOutlinePhone className="text-white text-center mx-auto text-7xl"/></a>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        <Newsletter />
    </>
  )
}

export default contact