import React from 'react'
import TerraYsaPic from "../../images/terra-ysa.jpg"
import Newsletter from '../Newsletter'
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { AiOutlinePhone } from "react-icons/ai";
import { Link } from 'react-router-dom'

const TerraYsa = () => {
  return (
    <>
        <div className="page-title">
            <h2 className="font-bold text-center pt-60 bg-sky-600 pb-20 text-white text-5xl">Projects</h2>
        </div>
        <section className='py-24'>
            <div className='container grid md:grid-cols-12 mx-auto px-4'>
                <div className='col-span-8'>
                    <h3 className="text-sky-500 font-bold text-3xl md:text-4xl my-8">Terraplus Ghana partners with YourSchoolAid (YSA)</h3>
                    <small className="text-stone-400 border-l-2 border-amber-500 p-4 mb-2 font-bold">4th July, 2023</small>
                    <img src={TerraYsaPic} alt="Terraplus and YourSchoolAid "/>
                    <div className='flex gap-8 mt-4'>
                        <a href='https://api.whatsapp.com/send/?phone=0545211903'><AiOutlinePhone className="text-2xl" /></a>
                        <a href='https://www.https://web.facebook.com/Terraplus-Ghana-107221035291174/'><BsFacebook className="text-2xl" /></a>
                        <a href='https://www.twitter.com/terraplus_ghana'><BsTwitter className="text-2xl" /></a>
                        <a href='https://www.instagram.com/terraplus_ghana/'><BsInstagram className="text-2xl" /></a>
                    </div>
                    <p className='text-lg text-stone-800 pb-10'>TerraPlus Ghana has joined hands with <span><a className='text-amber-400' rel='noreferrer' href="https://yourschoolaid.vercel.app" target='_blank'> YOURSCHOOLAID</a></span>, a school management system which aims to connect parents, teachers and students for academic success.</p>
                    <p className='text-lg text-stone-800 pb-10'>At Terraplus Ghana, we're excited to unveil a groundbreaking initiative that's set to transform the way primary education is approached "The Pri. Seg. Project." As a valued subscriber to our cutting-edge software, you're not just gaining access to a digital solution; you're becoming an integral part of a movement that empowers schools, students, and our environment for a brighter future</p>
                    <p className='text-lg text-stone-800 pb-10'>What is The Pri. Seg. Project?�"The Pri. Seg. Project" is more than just a name; it's a commitment to basic education's growth, nurturing potential, and fostering sustainable practices. By subscribing to our software, your school becomes an automatic beneficiary of this innovative project. Let's explore the exciting benefits awaiting you:</p>
                    <p className='text-lg text-stone-800 pb-10'>Holistic Digital Learning: Seamlessly transition assessments, assignments, and processes into the digital realm, enhancing the learning experience for students while boosting efficiency for teachers and administrators.</p>
                    <p className='text-lg text-stone-800 pb-10'>Environmental Stewardship: Embrace a paperless environment, aligning with Terraplus Ghana's core mission of sustainability. Your school contributes to a greener future and imparts valuable eco-consciousness to the young minds of today</p>
                    <p className='text-lg text-stone-800 pb-10'>Secure Data Management: Rest easy knowing that student and school data are safeguarded with the highest levels of security. Our software ensures compliance with privacy regulations, assuring parents and stakeholders alike</p>
                    <p className='text-lg text-stone-800 pb-10'>Time Optimization: Free up valuable time for educators and administrators by automating routine tasks. Focus on what truly matters, providing exceptional education and guidance to your students</p>
                    <p className='text-lg text-stone-800 pb-10'>Tailored Solutions: Our software adapts to your school's unique needs, offering customization that aligns perfectly with your existing systems and processes</p>
                    <p className='text-lg text-stone-800 pb-10'>Empowered Decision-Making: Gain real-time insights into student performance and progress, enabling informed decisions that lead to better outcomes and personalized support.</p>
                    <p className='text-lg text-stone-800 pb-10'>Enhanced Collaboration: Bridge the gap between teachers, parents, and students, fostering a strong educational community where communication flows seamlessly.</p>
                    <p className='text-lg text-stone-800 pb-10'>Join The Movement Today!�By subscribing to our software, you're not just upgrading your school's operations, you're actively participating in the transformation of primary education. Terraplus Ghana's "The Pri. Seg. Project" is our commitment to ensuring that today's students become tomorrow's leaders, equipped with knowledge, skills, and a deep understanding of environmental responsibility. Embark on this journey with us and let's nurture a brighter, more sustainable future together. Join "The Pri. Seg. Project" today!</p>
                </div>
                <div className="col-span-4 ">
                    <div className='project-card bg-white m-4 md:sticky md:top-24 rounded-lg shadow-xl p-4 h-fit'>fire
                    <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">Check out other projects</h2>
                    <p className='text-md my-4 text-stone-800'>Check out all that we have been able to accomplish since our founding</p>
                    <Link to={"/projects"}>
                    <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Check all projects</button>
                    </Link>
                    </div>
                </div>
            </div>
        </section>
        <Newsletter />
    </>
  )
}

export default TerraYsa