import React from 'react'

const Error = () => {
  return (
    <div className="py-24 mx-auto container px-4">
        <h3>Looks like the page you are looking for does not exist</h3>
    </div>
  )
}

export default Error