import React from 'react'

const News = () => {
  return (
    <>
    <div className="page-title">
        <h2 className="font-bold text-center pt-32 lg:pt-60 pb-20 text-white text-5xl">News & Updates</h2>
    </div>
    <section className='py-24'>
      <div className='container mx-auto px-4'>
        <p>No News & Update</p>
      </div>
    </section>
    </>
  )
}

export default News