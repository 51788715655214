import React from 'react'
import Gee from "../images/gee.jpg"
import stephen from "../images/stephen.jpg"
import mabel from "../images/mabel.jpg"
import ernest from "../images/ernest.jpg"
import isaac from "../images/isaac1.jpg"
import godfred from "../images/godfred1.jpg"
import hilda from "../images/hilda1.jpg"
import florence from "../images/florence1.jpg"

const TheTeam = () => {
  return (
    <section className='py-24'>
        <div className='container mx-auto px-4'>
          <h3 className='py-4 text-4xl md:text-5xl text-sky-500 font-bold mb-8'>The TerraPlus Team</h3>
          <div className='grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
            <div className="member-card border rounded-lg shadow-lg">
              <img src={stephen} alt="Boafo Stephen Junior, CEO of Terraplus Ghana"/>
              <div className='p-8'>
                <p className='text-lg text-black font-bold'>Mr. Boafo Stephen Junior</p>
                <p className='text-md text-black'>Chief Executive Officer of TerraPlus Ghana</p>
              </div>
            </div>
            <div className="member-card border rounded-lg shadow-lg">
              <img src={mabel} alt="Mabel, COO of Terraplus Ghana"/>
              <div className='p-8'>
                <p className='text-lg text-black font-bold'>Miss. Mabel Gyamfuaa</p>
                <p className='text-md text-black'>Chief Auditing Director of TerraPlus Ghana</p>
              </div>
            </div>
            <div className="member-card border rounded-lg shadow-lg">
              <img src={ernest} alt="Atuwo Ernest, COO of Terraplus Ghana"/>
              <div className='p-8'>
                <p className='text-lg text-black font-bold'>Mr. Atuwo Ernest</p>
                <p className='text-md text-black'>Chief Operations Officer of TerraPlus Ghana</p>
              </div>
            </div>
            <div className="member-card border rounded-lg shadow-lg">
              <img src={hilda} alt="Miss Hilda Abena Leh Seyram, Chief Financial Officer"/>
              <div className='p-8'>
                <p className='text-lg text-black font-bold'>Miss Hilda Abena Leh Seyram</p>
                <p className='text-md text-black'>Chief Financial Officer of TerraPlus Ghana</p>
              </div>
            </div>
            <div className="member-card border rounded-lg shadow-lg">
              <img src={Gee} alt="George Mensah, Information DIrector of Terraplus Ghana"/>
              <div className='p-8'>
                <p className='text-lg text-black font-bold'>Mr George Mensah Agyapong</p>
                <p className='text-md text-black'>Information Technology Director of TerraPlus Ghana</p>
              </div>
            </div>
            <div className="member-card border rounded-lg shadow-lg">
              <img src={florence} alt="Miss Florence Danso, Chief Information Officer"/>
              <div className='p-8'>
                <p className='text-lg text-black font-bold'>Miss Florence Danso</p>
                <p className='text-md text-black'>Chief Information Officer of TerraPlus Ghana</p>
              </div>
            </div>
            <div className="member-card border rounded-lg shadow-lg">
              <img src={godfred} alt="Owusu Achiaw Godfred, Media Creations Director"/>
              <div className='p-8'>
                <p className='text-lg text-black font-bold'>Mr Yaw Owusu Achiaw Godfred</p>
                <p className='text-md text-black'>Media Creations Director of TerraPlus Ghana</p>
              </div>
            </div>
            <div className="member-card border rounded-lg shadow-lg">
              <img src={isaac} alt="George Mensah, Public Relations officer"/>
              <div className='p-8'>
                <p className='text-lg text-black font-bold'>Mr Karikari Isaac</p>
                <p className='text-md text-black'>Public Relations Officer of TerraPlus Ghana</p>
              </div>
            </div>
            
          </div>
        </div>
      </section>
  )
}

export default TheTeam