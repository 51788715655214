import React from 'react'
import { createRoot } from "react-dom/client"
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import './index.css'
import Homepage from './Pages/Homepage'
import About from './Pages/About'
import Donate from './Pages/Donation'
import Membership from './Pages/Membership'
import Projects from './Pages/Projects'
import News from './Pages/News'
// import Terrafacts from './Pages/Terrafacts'
import Contact from './Pages/Contact'
import Thanks from "./Pages/Thanks"
import TreePlanting from './Components/Projects/TreePlanting'
import CharityDonation from './Components/Projects/CharityDonation'
import StreetCampaign from './Components/Projects/StreetCampaign'
import TerraYsa from './Components/Projects/TerraYsa'
import Terrafacts from './Components/Projects/Terrafacts'
import Podcast from './Components/Projects/Podcast'

import Error from "./Components/Error"

const SharedLayout = React.lazy( () =>{
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./Components/SharedLayout')))
  })
})

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={ 
        <React.Suspense>
          <SharedLayout />
        </React.Suspense>}>
        <Route index element={ <Homepage />} />
        <Route element={ <Homepage />} />
        <Route path='/about' element={<About />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/membership' element={<Membership />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/donate' element={<Donate />} />
        <Route path='/news' element={<News />} />
        <Route path='/terrafacts' element={<Terrafacts />} />
        <Route path='/thanks' element={<Thanks />} />
        <Route path='/projects/Terraplus-Ghana-tree-planting-exercise-2022/' element={<TreePlanting />} />
        <Route path='/projects/TerraPlus-Ghana-donates-to-King-Jesus-Charity-Home/' element={<CharityDonation />} />
        <Route path='/projects/TerraPlus-Ghana-embarks-on-beat-plastic-pollution-campaign/' element={<StreetCampaign />} />
        <Route path='/projects/TerraPlus-Ghana-partners-with-YSA' element={<TerraYsa />} />
        <Route path='/projects/TerraPlus-Ghana-terrafacts' element={<Terrafacts />} />
        <Route path='/projects/TerraPlus-Ghana-podcast' element={<Podcast />} />
        <Route path="*" element={<Error />} />
      </Route>
    </>
  )
)

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
