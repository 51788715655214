import React from 'react'

const Newsletter = () => {
  return (
    <section className='container rounded-xl my-24 mx-auto px-4 newsletter bg-sky-800'>
        <div className='flex flex-col md:flex-row justify-evenly text-white py-24 px-24'>
            <h3 className='text-4xl md:text-5xl font-bold'>Get Updated <br /> <p className='text-lg'>Subscribe our newsletter</p></h3>
            <form className="md:flex m-4" enctype="multipart/form-data" action="https://formsubmit.co/membership.terraplus@gmail.com" method="POST">
            <input className="d-none" type="hidden" name="Newsletter" />
            <input type="hidden" name="_next" value="https://terraplusghana.org/thanks" />
            <input style={{display: "none"}} type={"_name"} value={"https://terraplusghana.org/thanks"} />
            <input type={"text"} placeholder={"Enter Mail Address"} className="p-2 text-black m-2"  />
            <input type={"submit"} className="hover:cursor-pointer mx-2 button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800" />
            </form>
        </div>
    </section>
  )
}

export default Newsletter