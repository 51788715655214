import React from 'react'
import Newsletter from "../Components/Newsletter"

const membership = () => {
  return (
    <>
      <div className="page-title">
        <h2 className="font-bold text-center pt-32 lg:pt-60 pb-20 text-white text-5xl">Join Us</h2>
      </div>
      <section className='membership py-24 bg-slate-50'>
        <div className="creed container mx-auto px-4">
          <h3 className='text-3xl md:text-4xl text-sky-500 font-bold mb-4'>The TerraPlus Volunteer's (Rangers) Creed</h3>
          <p className='py-5 text-lg text-stone-800'>Recognizing that I volunteered as a member, fully knowing the challenges of my chosen path, will I always endeavour to uphold the prestige, honour and high spirit de corps of this organization</p>
          <p className='py-5 text-lg text-stone-800'>Acknowledging the fact that a Rnger is a more courageous, discipline and committed fellow who arrives at the cutting edge of his or her duties devouring his or her time and skills to the growth and operations for this organization, I accept the fact that as a Ranger of this organization, the world expects me to be every caring, affectionate and conscious about the environment than any other person in this world.</p>
          <p className='py-5 text-lg text-stone-800'>Never shall I fail my organization! <br /> I will always keep myself mentally alert, physically available and morally straight and i will shoulder more than my share of the task whatever it may be a 100% and the some!</p>
          <p className='py-5 text-lg text-stone-800'>Gallantly will I show the world that I am an agent of change and a fighter for an environmentally sustainable world for the current and future generations to come. <br /> My courtesy to my Executives and supervisors, neatness of dress and care of equipment shall set examples for others. <br /> Energetically will I advocate for the environmental well-being to the world!</p>
          <p className='py-5 text-lg text-stone-800'>Readily will I represent TerraPlus with all pride and grace and display the intestinal fortitude required to fight onto the ranger objective and complete the mission though i be alone.</p>
          <p className='py-5 text-lg text-stone-800'>Rangers lead the way!</p>
          <p className='py-5 font-bold text-lg text-stone-800'>TERRAPLUS GHANA, SERVICE TO MOTHER EARTH</p>
        </div>
        <div className='container mx-auto mt-24 px-4'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
            <div className='benefits'>
              <h3 className='text-3xl md:text-4xl text-sky-500 font-bold mb-4'>TerraPlus Ghana Membership Benefits</h3>
              <ul>
                <li className='text-lg text-stone-800 py-2'>Gaining experience on the field of environmental science, leadership, corporation, innovation.</li>
                <li className='text-lg text-stone-800 py-2'>Networking with people with unique perceptions, ideas and initiatives.</li>
                <li className='text-lg text-stone-800 py-2'>Serving your internships</li>
                <li className='text-lg text-stone-800 py-2'>Making environmental impact</li>
                <li className='text-lg text-stone-800 py-2'>Receiving incentives from time to time</li>
                <li className='text-lg text-stone-800 py-2'>Being employed into the organization</li>
                <li className='text-lg text-stone-800 py-2'>Learning new skills as well as advancing on old skills through workshops</li>
                <li className='text-lg text-stone-800 py-2'>Invitations to members-only events</li>
                <li className='text-lg text-stone-800 py-2'>Receiving monthly news letters from the organization</li>
              </ul>
            </div>
            <form action="https://formsubmit.co/membership.terraplus@gmail.com" method="POST" className='fill-form bg-white text-lg text-stone-800 border-2 shadow-lg rounded-lg p-4'>
              <input type="hidden" name="_next" value="https://terraplusghana.org/thanks"></input>
              <h3 className='text-3xl md:text-4xl text-sky-500 font-bold mb-4'>Fill Membership Form</h3>
              <div className='form-group my-8'>
                <label for="name">Enter Full Name</label>
                <input required className='border p-4 hover:shadow-md w-full' name='FullName' type="text" placeholder="Name" />
              </div>
              <div className='form-group my-8'>
                <label for="Email">Enter Email</label>
                <input required className='border p-4 hover:shadow-md w-full' name='Email' type="email" placeholder="email" />
              </div> 
              <div className='form-group my-8'>
                <label for="Email">Enter Date of Birth</label>
                <input required className='border p-4 hover:shadow-md w-full' name='DateofBirth' type="date" placeholder="email" />
              </div>
              <div className='form-group my-8 '>
                <label for="countrycode">Enter Country Code | Phone Number</label>
                <div className='flex'>
                  <input required max="999" className='border w-3/12 p-4 hover:shadow-md' name='countrycode' type="number" placeholder="+000 Country Code" />
                  <input required max="9999999999" className='border w-9/12 p-4 hover:shadow-md' name='phonenumber' type="number" placeholder="Phone Number" />
                </div>
              </div>
              <div className='form-group flex gap-x-12 my-8'>
                <input required className='border p-4 hover:shadow-md w-full' name='country' type="text" placeholder="Country" />
                <input required className='border p-4 hover:shadow-md w-full' name='city' type="text" placeholder="City" />
              </div>
              <div className='form-group my-8'>
                <label for="address">Enter Address</label>
                <input required className='border p-4 hover:shadow-md w-full' name='address' type="text" placeholder="Address" />
              </div>
              <div className='form-group my-8'>
                <label for="howYouHeardAboutUs">How did you hear about us?</label>
                <textarea className='border p-4 hover:shadow-md w-full' name='howYouHeardAboutUs' type="text" placeholder="Let us know how you heard about us?"></textarea>
              </div>
              <div className='form-group my-8'>
                <label for="howYouHeardAboutUs">Why do you want to join us?</label>
                <textarea className='border p-4 hover:shadow-md w-full' name='ReasonforJoining' type="text" placeholder="Why do you want to join us?"></textarea>
              </div>
              <div className='form-group m-2'>
                <input className="cursor-pointer border p-4 w-full button-primary text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:ring-sky-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-sky-700 focus:outline-none dark:focus:ring-sky-800 pointer" name="submit" value="Submit Form" type="submit"/>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Newsletter />
    </>
  )
}

export default membership