import React from 'react'
// import PodcastImage from "../../images/podcast.PNG"
import Newsletter from '../Newsletter'
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { AiOutlinePhone } from "react-icons/ai";
import { Link } from 'react-router-dom'
import { useEffect } from 'react';

const Podcast = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.tiktok.com/embed.js';
        script.async = true;
    
        // Create a function to initialize TikTok embed
        function initializeTikTokEmbed() {
          if (window.tiktok) {
            window.tiktok.load();
          }
        }
    
        // Attach an onload event listener to the script element
        script.addEventListener('load', initializeTikTokEmbed);
    
        // Append the script to the document's head
        document.head.appendChild(script);
    
        return () => {
          // Clean up the script element and event listener when the component unmounts
          script.removeEventListener('load', initializeTikTokEmbed);
          document.head.removeChild(script);
        };
      }, [])
  return (
    <>
        <div className="page-title">
            <h2 className="font-bold text-center pt-60 bg-sky-600 pb-20 text-white text-5xl">Projects</h2>
        </div>
        <section className='py-24'>
            <div className='container grid md:grid-cols-12 mx-auto px-4'>
                <div className='col-span-8'>
                    <h3 className="text-sky-500 font-bold text-3xl md:text-4xl my-8">Terraplus Ghana introduces Terrafacts</h3>
                    <small className="text-stone-400 border-l-2 border-amber-500 p-4 mb-2 font-bold">20th February, 2023</small>
                    <div className='flex flex-wrap gap-4'>
                        {/* <img style={{height: "500px"}} src={PodcastImage} alt="Terraplus and YourSchoolAid "/> */}
                        <div>
                            <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@terraplus_ghana/video/7231570736329084166" data-video-id="7231570736329084166" style={{width: "100%",minWidth: "100%", height: "100%"}} > 
                                <section> 
                                    <a target="_blank" rel="noreferrer" title="@terraplus_ghana" href="https://www.tiktok.com/@terraplus_ghana?refer=embed">@terraplus_ghana</a> 
                                    <p>IS THIS THE NEW COVID?😱</p> 
                                    <a target="_blank" rel="noreferrer" title="♬ original sound - TerraPlus Ghana" href="https://www.tiktok.com/music/original-sound-7231570783381392134?refer=embed">♬ original sound - TerraPlus Ghana</a> 
                                </section> 
                            </blockquote> 
                            <script async src="https://www.tiktok.com/embed.js"></script>
                        </div>
                    </div>
                    
                    <div className='flex gap-8 mt-4'>
                        <a href='https://api.whatsapp.com/send/?phone=0545211903'><AiOutlinePhone className="text-2xl" /></a>
                        <a href='https://www.https://web.facebook.com/Terraplus-Ghana-107221035291174/'><BsFacebook className="text-2xl" /></a>
                        <a href='https://www.twitter.com/terraplus_ghana'><BsTwitter className="text-2xl" /></a>
                        <a href='https://www.instagram.com/terraplus_ghana/'><BsInstagram className="text-2xl" /></a>
                    </div>
                    <p className='text-lg text-stone-800 pb-10'>Terrafacts is an initiative by Terraplus Ghana to educate the public on sentimental facts about the need in the attempt to conscientize our audience into joining the campaign for a sustainable environment.</p>
                    
                </div>
                <div className="col-span-4 ">
                    <div className='project-card bg-white m-4 md:sticky md:top-24 rounded-lg shadow-xl p-4 h-fit'>
                    <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">Check out other projects</h2>
                    <p className='text-md my-4 text-stone-800'>Check out all that we have been able to accomplish since our founding</p>
                    <Link to={"/projects"}>
                    <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Check all projects</button>
                    </Link>
                    </div>
                </div>
            </div>
        </section>
        <Newsletter />
    </>
  )
}

export default Podcast