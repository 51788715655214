import React from 'react'
import Vision from '../Components/Vision'
import TheTeam from '../Components/TheTeam'
import Newsletter from '../Components/Newsletter'

const Homepage = () => {
  return (
    <>
      <div className="page-title about-page">
        <h2 className="font-bold text-center pt-32 lg:pt-60 pb-20 text-white text-5xl">About Us</h2>
      </div>
      <section className='container mx-auto px-4 py-24'>
        <h3 className="text-4xl md:text-5xl text-sky-500 font-bold">Welcome to TerraPlus Ghana</h3>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-x-24'>
          <div>
            <p className='py-10 text-lg text-stone-800'>The underlining purpose of this organization is to create awareness to the public on environmental issues and how our day to day human activities cause gradual deterioration to the environment. The environment is the core foundation of life. All aspects of human life depend directly or indirectly on the environment. The foundation of this organization is based on scientific observations and the drive to attain a sustainable environment.</p>
            <p className='pb-10 text-lg text-stone-800'>Environmental issues vary from desertification, pollution, climate change, disasters, public health issues and waste management among others. Identifying these problems is not the challenge but rather developinng strategies to salvage these environmental issues..</p>
            <p className='text-lg text-stone-800'>This is a Non Profit Organisation that is geared towards the advocacy of the environment and its sustainabilty through identifying the problems, providing practical and effective solutions to environmental problems. <b>THIS IS TERRAPLUS GHANA, THIS IS SERVICE TO MOTHER EARTH.</b></p>
          </div>
          <div  className="py-10">
            <Vision />
          </div>
        </div>
      </section>
      <section className='bg-slate-50 py-10'>
        <div className=" container mx-auto px-4">
          <h3 className="text-4xl md:text-5xl text-sky-500 font-bold">The Core Values that Guide our Activities</h3>
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 m-12'>
            <div className="core-card  p-4 text-center border-2 rounded-lg">
              <div className='integrity-card'>
                <h3 className='py-4 text-2xl md:text-2xl lg:text-3xl text-amber-500 font-bold'>Integrity</h3>
                <p className='pt-4 pb-2 text-lg text-white font-bold'>We believe that integrity is the seed for achievement</p>
              </div>
              
            </div>
            <div className="core-card p-4 text-center border-2 rounded-lg">
              <div className='collaboration-card'>
              <h3 className='py-4 text-2xl md:text-2xl lg:text-3xl text-amber-500 font-bold'>Collaboration</h3>
              <p className='pt-4 pb-2 text-lg text-white font-bold'>We can only do so little individually but with collective team effort, we can achieve great things beyond expectations</p>
              </div>
              
            </div>
            <div className="core-card  p-4 text-center border-2 rounded-lg">
              <div className='deligence-card'>
              <h3 className='py-4 text-2xl md:text-2xl lg:text-3xl text-amber-500 font-bold'>Deligence</h3>
              <p className='pt-4 pb-2 text-lg text-white font-bold'>We are committed to achieving our visions and missions as we aspire to make great impact</p>
              </div>
              
            </div>
            <div className="core-card  p-4 text-center border-2 rounded-lg">
              <div className='transparency-card'>
              <h3 className='py-4 text-2xl md:text-2xl lg:text-3xl text-amber-500 font-bold'>Transparency</h3>
              <p className='pt-4 pb-2 text-lg text-white font-bold'>We believe that honesty, trust and accountability are the building units of a positive reputation as an ambitious organization</p>
              </div>
              
            </div>
            <div className="core-card p-4 text-center border-2 rounded-lg">
              <div className='innovation-card'>
              <h3 className='py-4 text-2xl md:text-2xl lg:text-3xl text-amber-500 font-bold'>Innovation</h3>
              <p className='pt-4 pb-2 text-lg text-white font-bold'>We aim to initiate unrelenting drive to break the status quo and develop anew wher few have dare ot go in regards to the environment and its affiliations</p>
              </div>
              
            </div>
            <div className="core-card p-4 text-center border-2 rounded-lg">
              <div className='commitment-card'>
              <h3 className='py-4 text-2xl md:text-2xl lg:text-3xl text-amber-500 font-bold'>Commitment</h3>
              <p className='pt-4 pb-2 text-lg text-white font-bold'>We are committed to materializing our goals and objectives and focused on making the world a better place</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <TheTeam />

      <Newsletter />
    </>
  )
}

export default Homepage