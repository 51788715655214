import React from 'react'
import { ImEyePlus } from "react-icons/im";
import unsdgLogo from "../images/global-goals.png"

const Vision = () => {
  return (
    <div className='vision-card my-4'>
              <div className='grid grid-cols-1'>
                <div className='flex'>
                  <div className='border-l-2 text-white border-t-2 w-1/2 bg-stone-800 p-4 text-center'>
                    <ImEyePlus className='text-9xl text-white mx-auto m-12' />
                  </div>
                  <div className='bg-sky-500 w-1/2 p-4'>
                    <h3 className="text-4xl md:text-5xl text-white font-bold mb-2">It is our vision to</h3>
                    <p className='text-lg text-white'>attain a safe, sustainable and enabling environment for the present and future generations.</p>
                  </div>
                  
                </div>
                <div className='flex'>
                  <div className="w-1/2 bg-white">
                    <a href={"https://unep.org"} target={"_blank"} rel="noreferrer"><p className="text-lg text-sky-500 hover:text-amber-800 p-4 mt-6">Find out More About the United Nations Sustainable Development Goals</p>
                    </a>
                  </div>
                  <a href="https://unep.org" target={"_blank"} rel="noreferrer" className='border-r-2 border-b-2 w-1/2 bg-stone-800 p-4 text-center'>
                    <img 
                      className="text-center p-4 mx-auto"
                      src={unsdgLogo}
                      alt="UNSDG Logo"
                      width={300}
                      height={300}
                    />
                  </a>
                </div>
                
              </div>
            </div>
  )
}

export default Vision