import React from 'react'
// import TerraYsaPic from "../../images/terra-ysa.jpg"
import Terrafacts1 from "../../images/terrafacts1.PNG"
import Terrafacts2 from "../../images/terrafacts2.PNG"
import Terrafacts3 from "../../images/terrafacts3.PNG"
import TerrafactsPic from "../../images/terrafacts.jpg"
import Newsletter from '../Newsletter'
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { AiOutlinePhone } from "react-icons/ai";
import { Link } from 'react-router-dom'

const TerraYsa = () => {
  return (
    <>
        <div className="page-title">
            <h2 className="font-bold text-center pt-60 bg-sky-600 pb-20 text-white text-5xl">Projects</h2>
        </div>
        <section className='py-24'>
            <div className='container grid md:grid-cols-12 mx-auto px-4'>
                <div className='col-span-8'>
                    <h3 className="text-sky-500 font-bold text-3xl md:text-4xl my-8">Terraplus Ghana introduces Terrafacts</h3>
                    <small className="text-stone-400 border-l-2 border-amber-500 p-4 mb-2 font-bold">20th February, 2023</small>
                    <img src={TerrafactsPic} alt="Terraplus and YourSchoolAid "/>
                    <div className='flex gap-8 mt-4'>
                        <a href='https://api.whatsapp.com/send/?phone=0545211903'><AiOutlinePhone className="text-2xl" /></a>
                        <a href='https://www.https://web.facebook.com/Terraplus-Ghana-107221035291174/'><BsFacebook className="text-2xl" /></a>
                        <a href='https://www.twitter.com/terraplus_ghana'><BsTwitter className="text-2xl" /></a>
                        <a href='https://www.instagram.com/terraplus_ghana/'><BsInstagram className="text-2xl" /></a>
                    </div>
                    <p className='text-lg text-stone-800 pb-10'>Terrafacts is an initiative by Terraplus Ghana to educate the public on sentimental facts about the need in the attempt to conscientize our audience into joining the campaign for a sustainable environment.</p>
                </div>
                <div className="col-span-4 ">
                    <div className='project-card bg-white m-4 md:sticky md:top-24 rounded-lg shadow-xl p-4 h-fit'>
                    <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">Check out other projects</h2>
                    <p className='text-md my-4 text-stone-800'>Check out all that we have been able to accomplish since our founding</p>
                    <Link to={"/projects"}>
                    <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Check all projects</button>
                    </Link>
                    </div>
                </div>
            </div>
            <div className='container mx-auto px-4 grid grid-cols-3 mt-10 pt-4 gap-4'>
                <div className='border border-gray border-solid p-2 shadow-md '>
                    <img src={Terrafacts1} alt='terrafacts1' className='mb-4' />
                    <p>Air pollutants from various sources may lead to a variety of health effects. A few have been captured on the image above. #stayhealthy #sdg2030 #unep</p>
                </div>
                <div className='border border-gray border-solid p-2 shadow-md '>
                    <img src={Terrafacts2} alt='terrafacts2' className='mb-4' />
                    <p>Carbons are beneficial environmental parameters to some extent but in rampant increment, pose a lethal threat to life on earth. During the pandemic, when indoor activities were high, there was a significant decrease in the rate of carbon emissions into the environment due to the controlled level of most anthropogenic activities. This shows the degree of the impact our actions have on the very environment we all depend on for survival.</p>
                </div>
                <div className='border border-gray border-solid p-2 shadow-md '>
                    <img src={Terrafacts3} alt='terrafacts3' className='mb-4' />
                    <p>Lassa Fever is a deadly viral illness first recorded in 1969 in a town called Lassa in Nigeria and has since spread to other West African countries like Benin, Sierra Leone, Liberia, Guinea, and Ghana. Lassa Fever is a deadly viral illness first recorded in 1969 in a town called Lassa in Nigeria and has since spread to other West African countries like Benin, Sierra Leone, Liberia, Guinea, and Ghana. </p>
                </div>
                <Link to={"https://www.instagram.com/terraplus_ghana/"}  className="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800" style={{width: "fit-content"}} target={"_blank"}>Click for more Terrafacts</Link>
            </div>
        </section>
        <Newsletter />
    </>
  )
}

export default TerraYsa