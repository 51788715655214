import React from 'react'
import charityDonation from "../../images/charityDonation.jpg"
import Newsletter from '../Newsletter'
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { AiOutlinePhone } from "react-icons/ai";
import { Link } from 'react-router-dom'

const CharityDonation = () => {
  return (
    <>
        <div className="page-title">
            <h2 className="font-bold text-center pt-60 bg-sky-600 pb-20 text-white text-5xl">Projects</h2>
        </div>
        <section className='py-24'>
            <div className='container grid md:grid-cols-12 mx-auto px-4'>
                <div className='col-span-8'>
                    <h3 className="text-sky-500 font-bold text-3xl md:text-4xl my-8">TerraPlus Ghana Donates to King Jesus Charity Home</h3>
                    <small className="text-stone-400 border-l-2 border-amber-500 p-4 mb-2 font-bold">13th August, 2022</small>
                    <img src={charityDonation} alt="tree planting "/>
                    <div className='flex gap-8 mt-4'>
                        <a href='https://api.whatsapp.com/send/?phone=0545211903'><AiOutlinePhone className="text-2xl" /></a>
                        <a href='https://www.https://web.facebook.com/Terraplus-Ghana-107221035291174/'><BsFacebook className="text-2xl" /></a>
                        <a href='https://www.twitter.com/terraplus_ghana'><BsTwitter className="text-2xl" /></a>
                        <a href='https://www.instagram.com/terraplus_ghana/'><BsInstagram className="text-2xl" /></a>
                    </div>
                    <p className='text-lg text-stone-800 py-10'>The value of life is not its duration but its donation” (Myles Munroe), this is why, Terraplus Ghana LBG, a newly formed NGO whose vision and mission is to create a sustainable and comfortable environmental conditions in the lives of people and the society,decided to donate to the King Jesus charity home on the 13th of August,2022. The charity home is situated at Boadi in the Ashanti region of Ghana which contains over 200 children, who are needy, disabled, orphans and also street children.</p>
                    <p className='text-lg text-stone-800 pb-10'>Terraplus Ghana LBG, since its inception in January, 2022, had this as part of their plans and worked so well till its achievement. Resources were gathered to purchase items such as, toiletries, rice, beverages, cooking oils, water packs and more.</p>
                    <p className='text-lg text-stone-800 pb-10'>Enquiries were made by the CEO (Mr. Stephen Boafo) and the finance officer/ project manager (Miss Hilda Seyram Abena Leh) about the place and chief information officer. (Miss Florence Afia Danso) contributed her part by purchasing the items with the help of the CEO. Preparations went on and on where T-shirts and caps were designed by Mr. Godfred Owusu (Media director) and sold to all executive members. Everything was set on the day and the time for the donation was 2pm.</p>
                    <p className='text-lg text-stone-800 pb-10'>We gathered and set off to the charity home, where we met the managing director of the home, Apostle Kofi Owusu Afriyie. He welcomed us warmly and gave us seats to occupy. Prayers were said by Miss Mabel Gyamfuah (Auditing director/Executive board secretary) and the welcome address by Mr. Atuwo (COO). The executives were asked to make introduction and a speech followed by the CEO.</p>
                    <p className='text-lg text-stone-800 pb-10'>In fact, it was all joy and this is just the beginning. May God strengthen us to be able to do more of these donations to the needy always, to give them comfort and love. Thank you</p>
                </div>
                <div className="col-span-4 ">
                    <div className='project-card bg-white m-4 md:sticky md:top-24 rounded-lg shadow-xl p-4 h-fit'>
                    <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">Check out other projects</h2>
                    <p className='text-md my-4 text-stone-800'>Check out all that we have been able to accomplish since our founding</p>
                    <Link to={"/projects"}>
                    <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Check all projects</button>
                    </Link>
                    </div>
                </div>
            </div>
        </section>
        <Newsletter />
    </>
  )
}

export default CharityDonation