import React from 'react'
import Newsletter from '../Newsletter'
import treePlanting1 from "../../images/treePlanting1.jpg"
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { AiOutlinePhone } from "react-icons/ai";
import { Link } from 'react-router-dom'

const TreePlanting = () => {
  return (
    <>
        <div className="page-title">
            <h2 className="font-bold text-center pt-60 bg-sky-600 pb-20 text-white text-5xl">Projects</h2>
        </div>
        <section className='py-24'>
            <div className='container grid md:grid-cols-12 mx-auto px-4'>
                <div className='col-span-8'>
                    <h3 className="text-sky-500 font-bold text-3xl md:text-4xl my-8">Terraplus Ghana Embarks on Tree Planting Exercise</h3>
                    <small className="text-stone-400 border-l-2 border-amber-500 p-4 mb-2 font-bold">5th June, 2022</small>
                    <img src={treePlanting1} alt="tree planting "/>
                    <div className='flex gap-8 mt-4'>
                        <a href='https://api.whatsapp.com/send/?phone=0545211903'><AiOutlinePhone className="text-2xl" /></a>
                        <a href='https://web.facebook.com/Terraplus-Ghana-107221035291174/'><BsFacebook className="text-2xl" /></a>
                        <a href='https://www.twitter.com/terraplus_ghana'><BsTwitter className="text-2xl" /></a>
                        <a href='https://www.instagram.com/terraplus_ghana/'><BsInstagram className="text-2xl" /></a>
                    </div>
                    <p className='text-lg text-stone-800 py-10'>As part of observing World Environment Day which is led by the United Nations Environment Programme (UNEP) and held annually on 5 June since 1973, Terra plus Ghana in collaboration with; the Environmental Leadership Forum, Sustainability Week- Kumasi, Arocha Ghana-KNUST, undertook a tree planting exercise in and around the environs of <b>KWAME NKRUMAH UNIVERSITY OF SCIENCE AND TECHNOLOGY.</b></p>
                    <p className='text-lg text-stone-800 pb-10'> World Environment Day, WED for short, was established by the UN General Assembly during the opening of the Stockholm Conference on Human environment. Its objective was to come up with ways of addressing the challenges of conserving and promoting the human environment. The celebration involves several activities; concerts, street rallies, tree planting, and clean-up activities, among others aimed at enhancing political attention and activities towards improving the environment. Its colors are blue, green, and brown depicting nature and the Earth. This year(2022), the theme for WED was dubbed ”ONLY ONE EARTH” which was hosted at Sweden. </p>
                    <p className='text-lg text-stone-800 pb-10'>Since the start of the industrial revolution, as organisms respire, carbonate rocks weather, volcanoes erupt, numerous gases have been released into the atmosphere of which carbon is the fourth most abundant. Carbon dioxide(CO2) is an important greenhouse gas that helps trap heat zin the atmosphere. Without it, our planet would be inhospitably cold. However, an increase in CO2 concentration in the atmosphere is causing average global temperatures to rise disrupting the Earth’s climate.</p>
                    <p className='text-lg text-stone-800 pb-10'>Trees affect the climate and therefore, the weather in three significant ways; lowering the atmospheric temperature, reducing energy usage, reducing air pollution, conserving water and supporting wildlife. Through the process of photosynthesis, trees remove carbon dioxide (a greenhouse gas) and release oxygen into the air. According the US Department of Agriculture, “one acre of forest absorbs six of carbon dioxide and put out four tons of oxygen which will meet the annual need of 18 people”. Each part of the plant contributes to climate control; from leaves to the root hence helping soak up carbon emitted into the atmosphere. Leaves absorb and filter the sun’s radiant energy, keeping things cool especially in the summer.</p>
                    <p className='text-lg text-stone-800 pb-10'>From the above listed importance of trees, we believe that our efforts to plant trees will contribute greatly to the preservation of our environment.</p>
                </div>
                <div className="col-span-4 ">
                    <div className='project-card bg-white m-4 md:sticky md:top-24 rounded-lg shadow-xl p-4 h-fit'>
                    <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">Check out other projects</h2>
                    <p className='text-md my-4 text-stone-800'>Check out all that we have been able to accomplish since our founding</p>
                    <Link to={"/projects"}>
                    <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Check all projects</button>
                    </Link>
                    </div>
                </div>
            </div>
        </section>
        <Newsletter />
    </>
  )
}

export default TreePlanting