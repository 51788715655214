import React from 'react'

const thanks = () => {
  return (
    <>
    <div className="page-title">
        <h2 className="font-bold text-center pt-32 lg:pt-60 pb-20 text-white text-5xl container mx-auto px-4">Forms has been successfully submitted. We will get back to you ASAP!</h2>
    </div>
    </>
  )
}

export default thanks