import React from 'react'
// import SwiperJs from '../Components/swiper'
import { Link } from 'react-router-dom';
import { FcSearch } from "react-icons/fc";
import { FcFlowChart } from "react-icons/fc";
import { FcIdea } from "react-icons/fc";
import { FcLike } from "react-icons/fc";
import { FcHighPriority } from "react-icons/fc";
import { FaHandshake } from "react-icons/fa";
import Vision from '../Components/Vision';
// import unsdgLogo from "../images/global-goals.png"
// import treePlanting1 from "../images/treePlanting1.jpg"
import donationImage from "../images/volunteers-packing-donation-boxes_74855-5299.webp"
import JoinUsImage from "../images/vacant-job-promo-with-join-us-message_52683-61756.webp"
import PodcastImage from "../images/podcast.PNG"
import StreetCampaign from "../images/streetCampaign.jpg"
// import CharityDonation from '../images/charityDonation.jpg';
import terrafacts from  "../images/terrafacts.jpg"
import TerraYsa from '../images/terra-ysa.jpg';
import TheTeam from '../Components/TheTeam';
import Newsletter from '../Components/Newsletter';

const Homepage = () => {
  return (
    <>
      <header className="header w-full pt-48">
        <div className='flex flex-col gap-y-5 header-content text-center container mx-auto px-4'>
          <h1 className="text-center text-xl sm:text-2xl lg:text-4xl text-white -mt-20 font-bold"> We can change the world and make it a better place. It is in our hands to make a difference.</h1>
          <Link to={"/contact"}>
            <button type="button" class="button-primary text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:ring-sky-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-sky-700 focus:outline-none dark:focus:ring-sky-800">Contact Us</button>
          </Link>
        </div>
      </header>

      <section className='container mx-auto px-4 pb-16'>
        <div className='md:grid md:grid-cols-3 gap-x-4 pt-24' >
          
          <div className="md:col-span-2">
            <h3 className="text-4xl md:text-5xl text-sky-500 font-bold">We engage in advocacy campaigns to raise awareness about environmental and economic sustainability issues</h3>
            <p className='py-10 text-lg text-stone-800'>In 2022, TerraPlus Ghana was established as a corporation limited by guarantee to act as an advocate for environmental sustainability, sanitation, and health. TerraPlus Ghana is trying to build an enabling environment in Ghana and beyond through environmental and economic sustainability.</p>
            <Link to={'/about'}>
              <button type="button" className="button-primary text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:ring-sky-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-sky-700 focus:outline-none dark:focus:ring-sky-800">More about us</button>
            </Link>
          </div>
          <div className='grid grid-cols-2 text-center text-sky-800'>
          <p className="col-span-2 text-left text-lg text-stone-800 mb-4">TerraPlus Ghana is a mission-driven organization that is driven by a set of core values. These are core values guide the actions, decisions, and behaviors of the organization and its members.</p>
            <div className='text-center flex flex-col justify-center alifn-center rounded-full bg-amber-500 m-2 p-2'>
              <FcLike className="mx-auto text-4xl text-center"/>
              <h5 className="text-white drop-shadow-md text-xl">Commitment</h5>
            </div>
            <div className='text-center flex flex-col justify-center alifn-center rounded-full bg-amber-500 m-2 p-2'>
              <FaHandshake className="mx-auto text-4xl text-center"/>
              <h5 className="text-white drop-shadow-md text-xl">Integrity</h5>
            </div>
            <div className='text-center flex flex-col justify-center alifn-center rounded-full bg-amber-500 m-2 p-2'>
              <FcHighPriority className="mx-auto text-4xl text-center"/>
              <h5 className="text-white drop-shadow-md text-xl">Deligence</h5>
            </div>
            <div className='text-center flex flex-col justify-center alifn-center rounded-full bg-amber-500 m-2 p-2'>
              <FcSearch className="mx-auto text-4xl text-center"/>
              <h5 className="text-white drop-shadow-md text-xl">Transparency</h5>
            </div>
            <div className='text-center flex flex-col justify-center alifn-center rounded-full bg-amber-500 m-2 p-2'>
              <FcFlowChart className="mx-auto text-4xl text-center"/>
              <h5 className='text-white drop-shadow-md text-xl'>Collaboration</h5>
            </div>
            <div className='text-center flex flex-col justify-center alifn-center rounded-full bg-amber-500 m-2 p-2'>
              <FcIdea className="mx-auto text-4xl text-center"/>
              <h5 className="text-white drop-shadow-md text-xl">innovation</h5>
            </div>
            
          </div>
        </div>
      </section>

      {/* Mission */}
      <section className="mission-vision bg-slate-100 py-24">
        <div className='container mx-auto px-4'>
          <div className='grid md:grid-cols-2 gap-x-8 text-white'>
            <div className="mission-card shadow-2xl rounded-lg bg-white p-4">
              <h3 className="text-4xl md:text-5xl text-amber-600 font-bold mb-4">Our Mission is to</h3>
              <p className='text-left text-lg text-stone-800'>educate people on environmental issues by embarking on environmental awareness campaigns, enlightening people on the need for a clean environment through environmental sustainabilty programs and environmental advocacy programs, assessing and reviewing environmental policies with other stakeholders of the environment, adopting multi-sectorial approach in tackling environmental issues and creating opportunities. TerraPlus Ghana prioritizes our work in these focused areas in line with the <a href={"https://www.unep.org"} rel="noreferrer" target={"_blank"}> United Nations Envrionment Program (UNEP)</a></p>
              <ul className='text-left text-lg text-stone-800 flex flex-wrap gap-y-4 gap-x-8'>
                <li>Climate change</li>
                <li>Ecosystem management</li>
                <li>Disaster management</li>
                <li>Resource efficiency and food security</li>
                <li>Chemicals and waste management</li>
                <li>Environmental governance</li>
                <li>Environmental under review</li>
              </ul>
            </div>
            <Vision />
          </div>
        </div>
      </section>

      {/* the team */}
      <TheTeam />

      {/* What We Do */}
      {/* <section className='bg-slate-50 py-24'>
        <div className='container mx-auto px-4'>
        <h2 className='col-span-12 mb-10 text-4xl md:text-5xl font-bold text-sky-600'>What We Do</h2>
        <div className='flex'>
          <div>What We Do here. so the idea is that the services we render will be listed here and as it gets highlighted and bold its corresponding image will be displayed in the column beside/below it</div>
          <div>Then an Image illustration here</div>
        </div>
        </div>
      </section> */}
      
      {/* projects */}
      <section className='projects my-24'>
        <div className='md:grid md:grid-cols-12 container mx-auto px-4'>
          <h2 className='col-span-12 mb-10 text-4xl md:text-5xl font-bold text-sky-600'>Take a look at the amazing initiatives we have undertaken since our founding</h2>
          <div className='our-numbers text-center col-span-3 md:flex md:flex-col gap-10'>
            <div className='years my-4'>
              <h2 className="text-7xl text-sky-600 font-bold">1<span className='text-md font-normal text-amber-600'><sup>+</sup></span></h2>
              <p className='text-lg'>years of service</p>
            </div>
            <div className='volunteers my-4'>
              <h2 className="text-7xl text-sky-600 font-bold">30<span className='text-md font-normal text-amber-600'><sup>+</sup></span></h2>
              <p className='text-lg'>committed volunteers</p>
            </div>
            <div className='volunteers my-4'>
              <h2 className="text-7xl text-sky-600 font-bold">6<span className='text-md font-normal text-amber-600'><sup>+</sup></span></h2>
              <p className='text-lg'>Projects</p>
            </div>
            <div className='customers my-4'>
              <h2 className="text-7xl text-sky-600 font-bold">500<span className='text-md font-normal text-amber-600'><sup>+</sup></span></h2>
              <p className='text-lg'>satisfied beneficiaries</p>
            </div>
          </div>
          <div className='col-span-9 project-img py-4'>
            <div className='project-big-card grid sm:grid-cols-2 lg:grid lg:grid-cols-3 gap-2 mb-4'>
              <Link to="/projects/TerraPlus-Ghana-partners-with-YSA" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
                <small className="text-stone-500">15th August, 2023</small>
                <p className='text-md my-4 text-stone-800'></p>
                <img 
                  src={TerraYsa}
                  alt="project logo"
                />
                <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana partners with YourSchoolAid (YSA)</h2>
                <p className='text-md my-4 text-stone-800'>TerraPlus Ghana has joined hands with YourSchoolAid, a school management system which aims to connect parents, teachers and students for academic success.</p>
                <Link to="/projects/TerraPlus-Ghana-partners-with-YSA">
                  <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
                </Link>
              </Link>
              <Link to="/projects/TerraPlus-Ghana-embarks-on-beat-plastic-pollution-campaign" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
                <small className="text-stone-500">4th July, 2023</small>
                <img 
                  src={StreetCampaign}
                  alt="project logo"
                />
                <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana embarks on a "beat plastic pollution" street campaign </h2>
                <p className='text-md my-4 text-stone-800'>An extraordinary event unfolded on the 4th of June at 15:00 GMT, as TerraPlus Ghana took a bold step forward in their mission to combat plastic pollution.</p>
                <Link to={"/projects/TerraPlus-Ghana-embarks-on-beat-plastic-pollution-campaign"}>
                  <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
                </Link>
              </Link>
              <Link to="/projects/TerraPlus-Ghana-podcast" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
                <small className="text-stone-500">11th May, 2023</small>
                <img 
                  src={PodcastImage}
                  alt="project logo"
                />
                <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana Podcast </h2>
                <p className='text-md my-4 text-stone-800'> The podcast initiative by terraplus utilises audio-visual approach to educate our targeted audience on environmental issues</p>
                <Link to={"/projects/TerraPlus-Ghana-podcast"}>
                  <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
                </Link>
              </Link>
              <Link to='/projects/TerraPlus-Ghana-terrafacts' className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
                <small className="text-stone-500">20th February, 2023</small>
                <img 
                  src={terrafacts}
                  alt="project logo"
                />
                <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">Terrafacts </h2>
                <p className='text-md my-4 text-stone-800'>Terrafacts is an initiative by Terraplus Ghana to educate the public on sentimental facts about the need in the attempt to conscientize our audience into joining the campaign for a sustainable environment</p>
                <Link to={'/projects/TerraPlus-Ghana-terrafacts'}>
                  <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
                </Link>
              </Link>
              {/* project 2 */}
              {/* <Link to="/projects/TerraPlus-Ghana-donates-to-King-Jesus-Charity-Home" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
                <small className="text-stone-500">13th August, 2022</small>
                <img 
                  src={CharityDonation}
                  alt="project logo"
                />
                <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana Donates to King Jesus Charity Home</h2>
                <p className='text-md my-4 text-stone-800'>TerraPlus Ghana LBG, a newly formed NGO whose mission and vision is to create a sustainable and comfortable environmental conditions...</p>
                <Link to={"/projects/TerraPlus-Ghana-donates-to-King-Jesus-Charity-Home"}>
                  <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
                </Link>
              </Link> */}
              {/* project 1 */}
              {/* <Link to="/projects/Terraplus-Ghana-tree-planting-exercise-2022/" className='project-card bg-white m-4 rounded-lg shadow-xl p-4'>
                <small className="text-stone-500">5th June, 2022</small>
                <img 
                  src={treePlanting1}
                  alt="project logo"
                />
                <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">TerraPlus Ghana Embarks on Tree Planting Exercise</h2>
                <p className='text-md my-4 text-stone-800'>Terra plus Ghana in collaboration with other environmental bodies undertook a tree planting exercise in and around the environs of KWAME NKRUMAH UNIVERSITY OF SCIENCE AND TECHNOLOGY.</p>
                <Link to={"/projects/Terraplus-Ghana-tree-planting-exercise-2022/"}>
                  <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Read More</button>
                </Link>
              </Link> */}
              <div className='project-card bg-white m-4 rounded-lg shadow-xl p-4 h-fit'>
                <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">Check out all our projects</h2>
                <p className='text-md my-4 text-stone-800'>Check out all that we have been able to accomplish since our founding</p>
                <Link to={"/projects"}>
                  <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Check all projects</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* join / donation */}
      <section className='join-donate bg-slate-100 py-8'>
        <div className='md:grid md:grid-cols-12 container mx-auto my-24 px-4'>
          <div className='bg-white col-span-6 p-4 mx-4 shadow-md'>
            <h3 className='text-4xl md:text-5xl my-4 text-amber-600 font-bold'>“Coming together is a beginning. Keeping together is progress. Working together is success.” <span><small className='text-sm text-stone-800'> by Henry Ford</small></span></h3>
            <div className='flex flex-col md:flex-row gap-12 mb-8'>
              <Link to="https://www.freepik.com/free-vector/vacant-job-promo-with-join-us-message_13762394.htm#query=join%20us&position=36&from_view=search&track=ais">
                <img
                  src={JoinUsImage}
                  alt='Join us'
                  width={200}
                  height={200}
                  className="text-center mx-auto w-full"
                />
              </Link>
              <p className='text-lg text-stone-800 my-2'>Become a member of TerraPlus Ghana and help us create a sociophysical environmental change</p>
            </div>
            <Link className='text-center' to={"/membership"}>
              <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Become a Member</button>
            </Link>
          </div>
          <div className='bg-white col-span-6 p-4 mx-4 shadow-md'>
            <h3 className='text-4xl md:text-5xl my-4 text-sky-600 font-bold'>Every penny you donate goes a long way in making a difference</h3>
            <div className='flex flex-col md:flex-col'>
              <Link to="https://www.freepik.com/free-vector/volunteers-packing-donation-boxes_7732672.htm#query=donate&position=14&from_view=search&track=sph">
                <img
                width={200}
                height={200}
                className="text-center mx-auto w-full"
                 src={donationImage} alt="donation"/>
              </Link>
              <p className='my-4 text-lg text-stone-800'>We ask that you join this train of humanity to help advocate for the environment. Please donate money or any other resource(s) deemed fit to help in our expedition.</p>
            </div>
            <Link className='text-center' to={"/donation"}>
              <button type="button" class="button-primary text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:ring-sky-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-sky-700 focus:outline-none dark:focus:ring-sky-800">Donate</button>
            </Link>
          </div>
        </div>
      </section>

      {/* News & updates */}
      <section className='news container mx-auto px-4 my-24'>
        <h3 className='text-4xl md:text-5xl my-4 text-sky-600 font-bold text-center'> News and Updates</h3>
        <p className='text-center text-lg'>No news posted</p>
      </section>

      {/* news letter */}
      <Newsletter />
    </>
  )
}

export default Homepage