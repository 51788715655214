import React from 'react'
import StreetCampaign from "../../images/streetCampaign.jpg"
import Newsletter from '../Newsletter'
import { BsFacebook } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { AiOutlinePhone } from "react-icons/ai";
import { Link } from 'react-router-dom'

const CharityDonation = () => {
  return (
    <>
        <div className="page-title">
            <h2 className="font-bold text-center pt-60 bg-sky-600 pb-20 text-white text-5xl">Projects</h2>
        </div>
        <section className='py-24'>
            <div className='container grid md:grid-cols-12 mx-auto px-4'>
                <div className='col-span-8'>
                    <h3 className="text-sky-500 font-bold text-3xl md:text-4xl my-8">TerraPlus Ghana Embarks on a "beat plastic pollution" street Campaign</h3>
                    <small className="text-stone-400 border-l-2 border-amber-500 p-4 mb-2 font-bold">4th July, 2023</small>
                    <img src={StreetCampaign} alt="tree planting "/>
                    <div className='flex gap-8 mt-4'>
                        <a href='https://api.whatsapp.com/send/?phone=0545211903'><AiOutlinePhone className="text-2xl" /></a>
                        <a href='https://www.https://web.facebook.com/Terraplus-Ghana-107221035291174/'><BsFacebook className="text-2xl" /></a>
                        <a href='https://www.twitter.com/terraplus_ghana'><BsTwitter className="text-2xl" /></a>
                        <a href='https://www.instagram.com/terraplus_ghana/'><BsInstagram className="text-2xl" /></a>
                    </div>
                    <p className='text-lg text-stone-800 pb-10'>An extraordinary event unfolded on the 4th of June at 15:00 GMT, as TerraPlus Ghana took a bold step forward in their mission to combat plastic pollution. Marking the highly anticipated World Environment Day, a significant annual observance orchestrated by the prestigious United Nations Environment Program, TerraPlus Ghana launched a powerful and thought-provoking plastic pollution awareness campaign, aptly titled 'Beat Plastic.'</p>
                    <p className='text-lg text-stone-800 pb-10'>The primary objective of this compelling initiative was to enlighten individuals about the dire consequences resulting from the 'careless' handling of plastics. TerraPlus Ghana sought to educate the masses on the origins, impacts, and potential solutions to the ever-growing predicament of plastic pollution. Their unwavering determination captured the attention of numerous passersby, drivers, and even street vendors. However, amidst the hustle and bustle of daily life, some individuals exhibited resistance, their busy schedules rendering them less receptive to the urgent message being delivered</p>
                    <p className='text-lg text-stone-800 pb-10'>Amidst the fervor of the campaign, a remarkable incident occurred, leaving an indelible mark on the minds of all those reset. Aconscientious driver stepped forward, demonstrating a remarkable technique to reduce the size of plastic sachets. With precision and finesse, he illustrated a process that involved tearing the soft center of the sachet, folding it horizontally into a compact rectangle, and ingeniously wrapping the remaining portion around it to form a neat square shape. This ingenious solution not only showcased the importance of individual actions but also provided practical guidance on how each person can contribute to the cause.</p>

                    <p className='text-lg text-stone-800 pb-10'> Meanwhile, TerraPlus Ghana's dedicated team engaged in meaningful one-on-one conversations with the diverse array of individuals encountered during the campaign. Passersby, drivers, and street vendors were given a unique opportunity to voice their concerns, share their experiences, and contribute to the ongoing dialogue surrounding plastic pollution. Simultaneously, other members of the team held eye-catching placards, strategically positioned along the bustling roadside, ensuring that the urgent message reached an even wider audience.Throughout the course of the campaign, TerraPlus Ghana made a profound impact, successfully reaching out to a multitude of individuals. Their tireless efforts and unwavering passion are poised to make a significant and lasting difference in reducing plastic pollution in and around the Tech Junction vicinity. By equipping people with knowledge, practical techniques, and a heightened sense of awareness, TerraPlus Ghana has planted the seeds of change, sowing the potential for a cleaner and more sustainable future for all.</p>
                </div>
                <div className="col-span-4 ">
                    <div className='project-card bg-white m-4 md:sticky md:top-24 rounded-lg shadow-xl p-4 h-fit'>
                    <h2 className="text-sky-600 my-4 hover:underline text-xl font-bold">Check out other projects</h2>
                    <p className='text-md my-4 text-stone-800'>Check out all that we have been able to accomplish since our founding</p>
                    <Link to={"/projects"}>
                    <button type="button" class="button-secondary text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-amber-300 font-medium rounded-full text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-amber-600 dark:hover:bg-amber-700 focus:outline-none dark:focus:ring-amber-800">Check all projects</button>
                    </Link>
                    </div>
                </div>
            </div>
        </section>
        <Newsletter />
    </>
  )
}

export default CharityDonation